import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "timi-login-container" }
const _hoisted_2 = { class: "timi-login-content" }
const _hoisted_3 = { class: "form" }
const _hoisted_4 = { class: "timi-form-item" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = {
  key: 0,
  class: "form-error"
}
const _hoisted_7 = {
  key: 1,
  class: "form-error"
}
const _hoisted_8 = { class: "timi-form-item" }
const _hoisted_9 = { class: "switch-form" }
const _hoisted_10 = {
  key: 0,
  class: "form-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('route.passwordForgotten')), 1),
      _createElementVNode("div", null, [
        _createElementVNode("form", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.email')), 1),
            _withDirectives(_createElementVNode("input", {
              name: "email",
              type: "email",
              placeholder: _ctx.$t('placeholder.mail'),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event)),
              autocomplete: "off"
            }, null, 8, _hoisted_5), [
              [_vModelText, _ctx.form.username]
            ]),
            (_ctx.errorClass('email', _ctx.form.username)=='required')
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('error.required')), 1))
              : _createCommentVNode("", true),
            (_ctx.errorClass('email', _ctx.form.username)=='wrongMail')
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('error.wrong_mail')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Button, {
              text: _ctx.$t('button.sendLink'),
              primary: true,
              raised: true,
              onOnClick: _ctx.submitForm,
              waitForResponse: _ctx.loading
            }, null, 8, ["text", "onOnClick", "waitForResponse"]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_router_link, { to: "/login" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('button.backToLogin')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          (_ctx.linkSent)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('success.linkSent')), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}