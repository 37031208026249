
import { defineComponent } from 'vue'
import { UserController } from '@/controller';
import { Button } from '@/ui';

export default defineComponent({
    name: "ForgotPassword",
    components: { Button },
    data(){
        return{
            form: {
                username: "",
            },
            validateForm: false,
            linkSent: false,
            loading: false,
            snackbar: {
                show: false,
                text: '',
                error: false
            },
            icons: {
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
            },
            loader: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 38" style="enable-background:new 0 0 38 38;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#B9C7D1;stroke-width:2;stroke-opacity:0.5;}.st1{fill:none;stroke:#163D64;stroke-width:2;}</style><g><g transform="translate(1 1)"><circle class="st0" cx="18" cy="18" r="18"/><path class="st1" d="M36,18c0-9.9-8.1-18-18-18"><animateTransform  accumulate="none" additive="replace" attributeName="transform" calcMode="linear" dur="1s" fill="remove" from="0 18 18" repeatCount="indefinite" restart="always" to="360 18 18" type="rotate"></animateTransform></path></g></g></svg>'
        }
    },
    methods: {
        errorClass(fieldType: any, input: any) {
        if (this.validateForm) {
            if (input == "") {
                return "required";
            }
            else if (fieldType == "email") {
                var re = /\S+@\S+\.\S+/;
                if (!re.test(input)) {
                    return "wrongMail";
                }
            }
        }
    },
    onValidateForm() {
        //NOL: Quick&Dirty Validation
        //check valid mail
        this.validateForm = true;
        var re = /\S+@\S+\.\S+/;
        if (!re.test(this.form.username)) {
            return false;
        }
        //check other fields
        if (this.form.username.length >= 3) {
            return true;
        }
        return false;
    },
    submitForm() {
        if (this.onValidateForm()) {
            this.sendPasswordLink();
            this.validateForm = false;
        }
        else {
            this.validateForm = true;
        }
    },
    async sendPasswordLink(){
        this.loading = true
        const res: any = await UserController.sendPasswordLink(this.form.username);
        if (res?.data?.success){
            this.linkSent = true;
        } else {
            this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            //this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
        }
        this.loading = false
    },
    /* handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error
        
            setTimeout(() => {
                this.snackbar.show = false
           }, 5000)
        } */
    }
})
